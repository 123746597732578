/*
 PRODUCT: BswUserSite

 Copyright © 2023 BlueSky Wireless Limited. All rights reserved.
 */

const cssClass = '.pagination_go_to_page'

export default class PaginationGoToPage {
    constructor (document) {
        this.document = document;
    }

    static goToPage (e) {
        e.preventDefault();

        const
            pageNumber = parseInt(prompt('Enter a page number to jump to'), 10),
            maxVal = parseInt(e.target.dataset.max, 10);

        if (pageNumber > 0 && pageNumber < maxVal) {
            let goToUrl = URI(window.location.href);
            goToUrl.setSearch('page', pageNumber);

            window.location.href = goToUrl.toString();
        }

        return false
    }

    init () {
        Array.from(this.document.querySelectorAll(cssClass)).forEach(el => {
            el.addEventListener('click', PaginationGoToPage.goToPage);
        });
    }
}
