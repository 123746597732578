/*
 PRODUCT: BswUserSite

 Copyright © 2023 BlueSky Wireless Limited. All rights reserved.
 */

export default class Cookies {
    static _initialised = false;

    static _cookies = {};

    static init () {
        const
            cookieString = document.cookie,
            parts = cookieString.split('; ');

        if (parts.length > 0) {
            parts.forEach(entry => {
                const entryParts = entry.split('=');

                if (entryParts.length === 2) {
                    Cookies._cookies[entryParts[0]] = unescape(entryParts[1]);
                }
            });
        }

        Cookies._initialised = true;
    }

    static getCookie (name) {
        if (!Cookies._initialised) {
            Cookies.init();
        }

        if (name in Cookies._cookies) {
            return Cookies._cookies[name];
        }

        return null;
    }

    static setCookie (name, value, expiresInDays) {
        document.cookie = `${name}=${encodeURIComponent(value)}; path=/; expires=Session`;
    }
}
