/*
 PRODUCT: BswUserSite

 Copyright © 2023 BlueSky Wireless Limited. All rights reserved.
 */

import Cookies from './cookies'

const SORT_ORDERING_EXT = 0;
const SORT_ORDERING_CONTACT = 1;
const SORT_ORDERING_COOKIE_NAME = 'bsw_message_sort_ordering';
const NAME_EXT_SEPARATOR = ' - ';

export default class SortOrdering {
    static _currentSortOrdering;

    static init () {
        const cookieValue = Cookies.getCookie(SORT_ORDERING_COOKIE_NAME);

        let updateCookie = true;

        if (cookieValue !== null) {
            try {
                SortOrdering._currentSortOrdering = parseInt(cookieValue, 10);

                if (SortOrdering._currentSortOrdering < SORT_ORDERING_EXT || SortOrdering._currentSortOrdering > SORT_ORDERING_CONTACT) {
                    SortOrdering._currentSortOrdering = SORT_ORDERING_EXT;
                } else {
                    updateCookie = false;
                }
            } catch (err) {
                SortOrdering._currentSortOrdering = SORT_ORDERING_EXT;
            }
        } else {
            SortOrdering._currentSortOrdering = SORT_ORDERING_EXT;
        }

        if (updateCookie) {
            Cookies.setCookie(SORT_ORDERING_COOKIE_NAME, SortOrdering._currentSortOrdering);
        }

        Array.from(document.querySelectorAll('[data-sort-order-toggle]')).forEach(element => {
            const sortOrder = parseInt(element.dataset.sortOrderToggle, 10);

            element.addEventListener('click', e => {
                e.preventDefault();

                const oldSortOrdering = SortOrdering._currentSortOrdering;

                SortOrdering._currentSortOrdering = sortOrder;
                Cookies.setCookie(SORT_ORDERING_COOKIE_NAME, sortOrder);

                SortOrdering._updateSortOrderingIndicators(sortOrder);

                SortOrdering._sortSelect(oldSortOrdering, sortOrder);

                return false;
            });
        });
    }

    static _updateSortOrderingIndicators (newSortOrder) {
        Array.from(document.querySelectorAll('[data-sort-order-toggle]')).forEach(element => {
            const sortOrder = parseInt(element.dataset.sortOrderToggle, 10);
            const oldInnerHtml = element.innerHTML.trim();
            if (sortOrder !== newSortOrder) {
                element.innerHTML = `&#9744; ${oldInnerHtml.substring(2)}`;
            } else {
                element.innerHTML = `&#9745; ${oldInnerHtml.substring(2)}`;
            }
        });
    }

    static _sortSelect (oldSortOrdering, newSortOrdering) {
        if (oldSortOrdering === newSortOrdering) {
            return;
        }

        Object.keys(window.choices).forEach((key) => {
            if (window.choices[key].isAddressBookSortable) {
                const
                    choicesInstance = window.choices[key].choices,
                    groups = choicesInstance.currentState.groups,
                    choicesList = choicesInstance.currentState.choices;

                const newData = {};

                for (let i = 0; i < groups.length; i++) {
                    newData[groups[i].id] = {
                        label: groups[i].value,
                        id: groups[i].id,
                        choices: []
                    };
                }

                for (let i = 0; i < choicesList.length; i++) {
                    if (!choicesList[i].label.includes(NAME_EXT_SEPARATOR)) {
                        continue;
                    }

                    let extension, name, label;

                    if (newSortOrdering === SORT_ORDERING_EXT) {
                        [extension, name] = SortOrdering._parsePromptForContact(choicesList[i].label);
                        label = `${extension} - ${name}`;
                    } else {
                        [extension, name] = SortOrdering._parsePromptForExtension(choicesList[i].label);
                        label = `${name} - ${extension}`;
                    }

                    newData[choicesList[i].groupId].choices.push({
                        value: choicesList[i].value,
                        label: label,
                        customProperties: {
                            extension: extension,
                            name: name,
                        },
                    });
                }

                choicesInstance.setChoices(Object.values(newData), 'value', 'label', true);

                const value = choicesInstance.getValue(true);
                choicesInstance.setValueByChoice(value);
            }
        });
    }

    static _parsePromptForContact (prompt) {
        const parts = prompt.split(NAME_EXT_SEPARATOR, 2);

        return [parts[1].trim(), parts[0].trim()];
    }

    static _parsePromptForExtension (prompt) {
        const parts = prompt.split(NAME_EXT_SEPARATOR, 2);

        return [parts[0].trim(), parts[1].trim()];
    }
};
