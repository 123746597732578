export function monitorInputToggles()
{
    const watchElements = document.querySelectorAll('input[data-bs-toggle-input-group]');

    Array.from(watchElements).forEach(el => {
        const targetInputGroup = el.dataset.toggleInputGroup;

        Array.from(document.querySelectorAll('[data-input-group]')).forEach(inputGroup => {
            const inputGroupName = inputGroup.dataset.inputGroup;

            if (el.checked) {
                if (inputGroupName === targetInputGroup) {
                    inputGroup.classList.remove('d-none');
                } else {
                    inputGroup.classList.add('d-none');
                }
            }
        });

        el.addEventListener('change', evt => {
            evt.preventDefault();

            Array.from(document.querySelectorAll('[data-input-group]')).forEach(inputGroup => {
                const inputGroupName = inputGroup.dataset.inputGroup;

                if (evt.target.checked) {
                    if (inputGroupName === targetInputGroup) {
                        inputGroup.classList.remove('d-none');
                    } else {
                        inputGroup.classList.add('d-none');
                    }
                }
            });
        });
    });
}
