/*
 PRODUCT: BswUserSite

 Copyright © 2023 BlueSky Wireless Limited. All rights reserved.
 */

import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/alert';
